Vue.component('paginationComponent',{
    props:['currentView','items'],
    watch: {
        'items.length':'itemUpdated',
    },
    data:function(){
        return {
            counter:0,
            itemsInView:10,
            mustByRender:0
        }
    },
    computed:{
        itemsPerPage:function(){
            let dummy= this.mustByRender;
            return this.$store.getters.getItemPerPage;
        },
        currentOffSet:function(){
            let dummy= this.mustByRender;
            let isolationValue = this.$store.getters.getOffset
            console.log('Isolation Value',isolationValue);
            return isolationValue;
        },
        initView:function(){
            let dummy= this.mustByRender;
            if(this.$store.getters.getOffset<this.itemsInView)
                return  0;
            else
                return 0
        },
        itemsCount:function () {
            let dummy= this.mustByRender;
            if(this.items) {
                switch(this.currentView) {
                    case 'customerGifts':
                        return this.$store.getters.getCustomerGifts.length;
                        break;
                    case 'favorites':
                        return this.$store.getters.getFavoritesItems.length;
                    case 'suggestions':
                        return this.$store.getters.getSuggestions.length;
                    case 'ecofriendly':
                        return this.$store.getters.getEcofriendlyItems.length;
                    default:
                        return this.items.filter(x => x.visible).length
                }
            }
            return 0;
        },
        offsetLength :function(){
            let dummy= this.mustByRender;
            if (this.itemsCount<this.itemsPerPage)
                return 1;
            if (this.itemsCount%this.itemsPerPage>0)
                return parseInt(this.itemsCount/this.itemsPerPage)+1
            else
                return  parseInt(this.itemsCount/this.itemsPerPage)
        },
        offStore:function(){
            let self = this;
            let dummy= this.mustByRender;
            this.counter=0;
            return Array.from({length: this.offsetLength }, () => self.counter++);
        }
    },
    methods:{
        changeOffset:function(offset){
            if(offset==undefined)
                offset=0;
            if(offset<0)
                offset=0;
            window.scrollTo(0, 0);
            this.$store.dispatch('updateCurrentPageOffset',offset);
            this.$store.dispatch('updateCurrentPage',this.currentView);
            console.log('Hello',offset,this.$store.getters.getOffset,this.$store.state.currentPage);
        },
        itemUpdated:function(){
            this.mustByRender=this.mustByRender+1;
            //console.log('eventReceived',this.mustByRender);
        }
    },
    mounted:function(){
        if(this.currentView!=this.$store.state.currentPage) {
            this.$store.dispatch('updateCurrentPageOffset', 0);
            this.$store.dispatch('updateCurrentPage',this.currentView);
        }
    },
    template:`<div class="row">
                <ul class="pagination col s12">
                    <li class="disabled" >
                        <span> Mostrando {{currentOffSet*itemsPerPage+1}} - {{Math.min(currentOffSet*itemsPerPage+itemsPerPage,itemsCount)}} de {{itemsCount}} </span>
                    </li>
                    <li :class="{'disabled':currentOffSet==0}">
                        <template v-if="currentOffSet==0">
                            <a href="#" @click.stop.prevent="()=> null">
                                <i class="material-icons">chevron_left</i>
                            </a>
                        </template>
                        <template v-else>
                            <a href="#" @click.stop.prevent="changeOffset(currentOffSet-1)">
                                <i class="material-icons">chevron_left</i>
                            </a>
                        </template>
                    </li>
                    <template v-for="offset of offStore.slice(initView,(currentOffSet+itemsInView))">
                        <li :class="{'active':offset==currentOffSet ,'waves-effect':true }" >
                            <a href="#" @click.stop.prevent="changeOffset(offset)">{{offset+1}}</a>
                        </li>
                    </template>    
                     <li :class="{'disabled':currentOffSet==offsetLength-1}">
                        <template v-if="currentOffSet==offsetLength-1">
                            <a href="#" @click.stop.prevent="()=> null">
                                <i class="material-icons">chevron_right</i>
                            </a>
                        </template>
                        <template v-else>
                            <a href="#" @click.stop.prevent="changeOffset(currentOffSet+1)">
                                <i class="material-icons">chevron_right</i>
                            </a>
                        </template>
                    </li>
                </ul>
            </div>`,
});
